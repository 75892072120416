import startCase from 'lodash/startCase';
import { useEffect, useMemo, useState } from 'react';
import { useEffectOnce, useInterval } from 'react-use';
import useSWRMutation from 'swr/mutation';

import {
  type DtoGamePack,
  type DtoProduct,
  type EnumsOneTimePurchaseUpsellPromoCode,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import partyPopper from '../../assets/img/party-popper.png';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { type GamePack } from '../../types/game';
import { getStaticAssetPath } from '../../utils/assets';
import { err2s } from '../../utils/common';
import { formatCurrency } from '../../utils/currency';
import { TimeUtils } from '../../utils/time';
import { Loading } from '../Loading';
import { ProductUtils } from '../Product/utils';

const upsellGamePackCovers = getStaticAssetPath(
  'images/upsell-gamepack-covers.png'
);
const upsellDurationSec = 600;

function RegularPriceCallout(props: { subscriptionPrice: ModelsPrice }) {
  return (
    <div className='flex flex-col items-center justify-center w-37.5 h-25 text-black'>
      <div>Regular Price</div>
      <div
        className='text-3xl font-bold line-through'
        style={{ textDecorationColor: '#FF0935' }}
      >
        {formatCurrency(props.subscriptionPrice.amount)}
      </div>
      <div>
        per{' '}
        {ProductUtils.FormatInterval(props.subscriptionPrice.billingInterval)}
      </div>
    </div>
  );
}

function FreePriceCallout(props: { freeTrialDurationLabel: string }) {
  return (
    <div className='flex flex-col items-center justify-center bg-lp-green-003 rounded-lg w-37.5 h-25 text-white'>
      <div>Today Only</div>
      <div className='text-3xl font-bold'>FREE</div>
      <div>for {props.freeTrialDurationLabel}</div>
    </div>
  );
}

function CompletionTimer(props: {
  ready: boolean;
  durationSeconds: number;
  onTimeout: () => void;
}) {
  const { ready, durationSeconds, onTimeout } = props;
  const [seconds, setSeconds] = useState(durationSeconds);

  useEffect(() => {
    if (seconds === 0) {
      onTimeout();
    }
  }, [seconds, onTimeout]);

  useInterval(
    () => {
      setSeconds((prev) => prev - 1);
    },
    ready && seconds > 0 ? 1000 : null
  );
  const formattedTime = TimeUtils.DurationFormattedHHMMSS(
    seconds * 1000,
    false
  );
  return (
    <div className='text-base lg:text-lg xl:text-xl font-bold text-center text-black'>
      This offer expires in{' '}
      <span className='tabular-nums'>{formattedTime}</span>
    </div>
  );
}

// note: assumes there's no fractional weeks or months
function getDurationLabel(days: number, shouldPluralize = false): string {
  if (days % 30 === 0) {
    return `${days / 30} month${shouldPluralize && days > 30 ? 's' : ''}`;
  }
  if (days % 7 === 0) {
    return `${days / 7} week${shouldPluralize && days > 7 ? 's' : ''}`;
  }

  return `${days} day${shouldPluralize && days > 1 ? 's' : ''}`;
}

export function OTPUpSellModal(props: {
  pack: DtoGamePack | GamePack;
  subscriptionProduct: DtoProduct;
  subscriptionPrice: ModelsPrice;
  trialPeriodDays: number;
  promoCode: EnumsOneTimePurchaseUpsellPromoCode;
  onComplete: () => void;
  completeBtnLabel: string;
  onCancel: () => void;
}) {
  const {
    pack,
    subscriptionProduct,
    subscriptionPrice,
    onCancel,
    promoCode,
    completeBtnLabel,
  } = props;
  const trialPeriodDays =
    props.trialPeriodDays > 30 ? 14 : props.trialPeriodDays;
  const analytics = useOneTimePurchaseAnalytics();

  const analyticsContext = useMemo(() => {
    return {
      gamePackId: pack.id,
      gamePackName: pack.name,
      productId: subscriptionProduct.id,
      priceId: subscriptionPrice.id,
      headcount: subscriptionPrice.maxSeats,
      promoCode,
    };
  }, [
    pack.id,
    pack.name,
    subscriptionPrice.id,
    subscriptionPrice.maxSeats,
    subscriptionProduct.id,
    promoCode,
  ]);

  const { trigger, isMutating, error } = useSWRMutation(
    `/api/gamepacks/${pack.id}/activate-upsell`,
    async () => {
      const resp = await apiService.gamePack.activateOTPUpsell(pack.id, {
        productId: subscriptionProduct.id,
        priceId: subscriptionPrice.id,
        promoCode,
        trialPeriodDays,
      });
      analytics.trackEventPostPurchaseUpsellAccepted(analyticsContext);
      return resp.data;
    },
    {
      revalidate: false,
    }
  );
  const [isActivated, setIsActivated] = useState(false);

  const handleActivate = useLiveCallback(async () => {
    if (isMutating || isActivated) return;
    await trigger();
    setIsActivated(true);
  });

  const handleDecline = useLiveCallback(() => {
    analytics.trackEventPostPurchaseUpsellDeclined(analyticsContext);
    onCancel();
  });

  useEffectOnce(() => {
    analytics.trackEventPostPurchaseUpsellViewed(analyticsContext);
  });

  const freeTrialDurationLabel = getDurationLabel(trialPeriodDays);
  const freeTrialDurationPluralizedLabel = getDurationLabel(
    trialPeriodDays,
    true
  );

  return (
    <div
      className={`
        relative w-4/5 h-140 rounded-xl min-w-90
        bg-gradient-to-b from-[#F8F8F8] via-[#FCDAE0] to-[#F8F8F8]
        flex flex-row-reverse animate-fade-in
      `}
    >
      <div className='absolute w-full lg:relative lg:w-1/2 h-full'>
        <img
          src={upsellGamePackCovers}
          alt='upsell'
          className='w-full h-full object-cover opacity-10 lg:opacity-80'
        />
      </div>

      <div
        className={`
          absolute w-full lg:relative lg:w-1/2 h-full px-4
          flex flex-col items-center ${
            isActivated ? 'justify-center gap-2' : 'justify-between'
          }
        `}
      >
        {isActivated ? (
          <>
            <img
              alt='party popper'
              src={partyPopper}
              className='w-25 h-25 mb-3'
            />
            <div className='font-bold text-lg lg:text-xl text-black text-center'>
              Your {freeTrialDurationLabel} free trial starts today!
            </div>
            <div className='text-black text-center w-4/5 md:w-full md:px-5 lg:text-lg'>
              You’ve got unlimited access to programs and our library of AI
              hosted team building games!
            </div>
            <button
              type='button'
              className='btn-primary mt-3 w-57.5 h-10'
              onClick={props.onComplete}
            >
              {completeBtnLabel}
            </button>
          </>
        ) : (
          <>
            <div>
              <div className='pt-6 pb-3.5 text-2xl lg:text-2.5xl xl:text-3.5xl font-bold text-red-006 text-center'>
                Limited Time Offer
              </div>
              <div className='text-base lg:text-lg xl:text-1.5xl text-center text-black px-4 lg:px-6'>
                Get {freeTrialDurationPluralizedLabel} of free access to our
                library with hundreds of experiences like{' '}
                <strong>{pack.name}</strong>
              </div>
            </div>

            <div className='flex items-center gap-10'>
              <RegularPriceCallout subscriptionPrice={subscriptionPrice} />
              <FreePriceCallout
                freeTrialDurationLabel={freeTrialDurationPluralizedLabel}
              />
            </div>

            <div className='pb-6 w-full flex flex-col items-center gap-2'>
              <CompletionTimer
                ready={!isMutating}
                durationSeconds={upsellDurationSec}
                onTimeout={onCancel}
              />
              {error && (
                <div className='text-red-002 text-sms text-center'>
                  {err2s(error)}
                </div>
              )}
              <button
                type='button'
                className='btn-primary w-1/2 min-w-57.5 h-12.5'
                onClick={handleActivate}
                disabled={isMutating}
              >
                {isMutating ? (
                  <Loading text='' />
                ) : (
                  `Activate ${startCase(freeTrialDurationLabel)} Free Trial`
                )}
              </button>
              <button
                type='button'
                className='btn text-center text-icon-gray text-sms my-3'
                onClick={handleDecline}
                disabled={isMutating}
              >
                No Thanks
              </button>
              <div className='w-3/4 text-xs text-black text-center'>
                Following introductory period, your unlimited membership will
                continue at {formatCurrency(subscriptionPrice.amount)} each{' '}
                {ProductUtils.FormatInterval(subscriptionPrice.billingInterval)}{' '}
                and you can cancel any time.
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
